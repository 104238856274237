const message = {
  header: {
    brand: "DANGOMANGA",
    menu: {
      about: "ABOUT",
      work: "WORK",
    },
  },
  introduct: "ようこそ",
  welcome: {
    title:
      "<span class='font-sans'>Dango Mangaは日本の漫画をタイに届けます。<br/>Dango Mangaは著作権者からコンテンツ使用許可を得た作品を翻訳し、販売しています。 <br/>私たちはタイで漫画を「買う」文化を育てていきます。</span>",
    subtitle: "<span class='font-sans'>sub title</span>",
    preSection: "出版作品",
  },
  others: "出版作品をもっとみる",
  partners: "お取引先企業",
  about: {
    established: "<span class='font-sans'>2020年9月14日 設立</span>",
    businessType: {
      title: "事業内容",
      text: "<b>紙書籍の翻訳出版・販売</b><br/> より多くの読者に満足していただくために製本の質を追求します。タイの読者のニーズに合うようデザインチームがローカライズ(紙の選定、デザイン変更等)のご提案をさせていただきます。また、ブックフェアへの出展を通じて、読者に作品の魅力を伝えています。<br/><br/> <b>漫画配信サイトの運営・電子書籍の出版</b><br/> すべてのOSで動作する、便利で美しいUX/UIを提供するブラウザベースの漫画配信サイトを運営いたします。また、タイの大手電子書籍販売サイト『meb』での販売も行っております。",
    },
    founder: {
      title: "取締役",
      text1: "Marutpong Noppakhro",
      text2: "Panatda Suebsang",
    },
    registered: {
      title: "資本金",
      text1: "1,000,000 baht",
      text2: "",
    },
  },
  contact: {
    name: "Dango Manga Co., Ltd.",
    address1: "343/312-313 Klong Lam Chiak, Nuan Chan,",
    address2: "Bueng Kum, Bangkok. 10230 Thailand",
    tel: "Tel",
    email: "Email",
  },
  series: {
    name: "Name",
    artBy: "Art by",
    storyBy: "Story by",
    publisher: "Publisher",
    copyright: "Copyright",
    thaiTranslate: "Thai translate by",
    size: "Size",
    type: "Type",
  },
};

export default message;
