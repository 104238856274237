
import { Options, Vue } from "vue-class-component";
import { translate } from "@/locales/i18n";

@Options({
  name: "Header",
})
export default class Header extends Vue {
  t = translate;
}
