import { createI18n } from "vue-i18n";
import th from "./th";
import ja from "./ja";

/**
 * Load locale messages
 *
 * The loaded `JSON` locale messages is pre-compiled by `@intlify/vue-i18n-loader`, which is integrated into `vue-cli-plugin-i18n`.
 * See: https://github.com/intlify/vue-i18n-loader#rocket-i18n-resource-pre-compilation
 */
// function loadLocaleMessages(): LocaleMessages<VueMessageType> {
//   const locales = require.context(
//     "./locales",
//     true,
//     /[A-Za-z0-9-_,\s]+\.json$/i
//   );
//   const messages: LocaleMessages<VueMessageType> = {};
//   locales.keys().forEach((key) => {
//     const matched = key.match(/([A-Za-z0-9-_]+)\./i);
//     if (matched && matched.length > 1) {
//       const locale = matched[1];
//       messages[locale] = locales(key).default;
//     }
//   });
//   return messages;
// }

const i18n = createI18n({
  legacy: false,
  lobalInjection: true,
  locale: "th",
  fallbackLocale: "th",
  messages: {
    th: th,
    ja: ja,
  },
});

export const translate = (key: string): any => {
  if (!key) {
    return "";
  }
  return i18n.global.t(key);
};

export const currentLocale = i18n.global.locale.value;

export default i18n;
