import { createApp } from "vue";
import VueSmoothScroll from "vue3-smooth-scroll";
import i18n from "./locales/i18n";
import App from "./App.vue";
import "@/assets/tailwind.css";
import "flowbite";
import router from "./router";

const app = createApp(App);

app.use(router);
app.use(i18n);
app.use(VueSmoothScroll);
app.mount("#app");
