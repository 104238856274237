import { createRouter, createWebHistory } from "vue-router";

const Landing = () => import("@/views/Landing.vue");
const Series = () => import("@/views/Series.vue");
const Demo = () => import("@/views/Demo.vue");
const NotFound = () => import("@/views/NotFound.vue");

const routes = [
  { path: "/", name: "Landing", component: Landing, alias: "/landing" },
  { path: "/series/:id", name: "Series", component: Series },
  { path: "/series/:id/demo", name: "Demo", component: Demo },
  { path: "/:pathMatch(.*)*", name: "NotFound", component: NotFound },
];

const router = createRouter({
  history: createWebHistory(process.env.VUE_APP_BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return {
        el: to.hash,
        behavior: "smooth",
      };
    }
  },
});

export default router;
