
import { Options, Vue } from "vue-class-component";
import Header from "@/components/Header.vue";

@Options({
  name: "App",
  components: { Header },
})
export default class App extends Vue {
  mounted() {
    this.$i18n.locale = "th";
  }
}
