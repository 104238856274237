const message = {
  header: {
    brand: "DANGOMANGA",
    menu: {
      about: "เกี่ยวกับเรา",
      work: "ผลงาน",
    },
  },
  introduct: "Welcome",
  welcome: {
    title:
      "พวกเราคือสำนักพิมพ์ดังโงะมังงะ เน้นโฟกัสการซื้อลิขสิทธิ์ และการแปลหนังสือการ์ตูน จากภาษาญี่ปุ่นเป็นภาษาไทย",
    subtitle: "subtitle",
    preSection: "ผลงานของเรา",
  },
  others: "อื่นๆ อีกมากมาย",
  partners: "พันธมิตรของเรา",
  about: {
    established: "ก่อตั้งเมื่อ 14 กันยายน 2563",
    businessType: {
      title: "ประเภทธุรกิจ",
      text: "สำนักพิมพ์หนังสือการ์ตูนญี่ปุ่นลิขสิทธิ์ถูกต้อง ซึ่งจัดจำหน่ายภายใต้บริษัท ดังโงะมังงะ จำกัด โดยการแปลการ์ตูนภาษาญี่ปุ่นเป็นภาษาไทยและจัดจำหน่ายในรูปแบบหนังสือเล่ม ซึ่งประกอบไปด้วย กระดาษที่มีคุณภาพสูงและมีความสวยงามในการเข้ารูปเล่ม  และหนังสืออิเล็กทรอนิกส์แพลตฟอร์ม MEB และเว็บไซต์ของบริษัทเอง",
    },
    founder: {
      title: "คณะกรรมการ",
      text1: "นายมรุตพงศ์ นพเคราะห์",
      text2: "นางสาวปนัดดา สืบแสง",
    },
    registered: {
      title: "ทุนจดทะเบียน",
      text1: "1,000,000 บาท",
      text2: "(หนึ่งล้านบาทถ้วน)",
    },
  },
  contact: {
    name: "บริษัท ดังโงะมังงะ จำกัด",
    address1: "343/312 ถนนคลองลำเจียก แขวงนวลจันทร์",
    address2: "เขตบึงกุ่ม กรุงเทพมหานคร 10230 ประเทศไทย",
    tel: "เบอร์โทร",
    email: "อีเมลล์",
  },
  series: {
    name: "ชื่อเรื่อง",
    artBy: "ภาพโดย",
    storyBy: "เนื้อเรื่องโดย",
    publisher: "สำนักพิมพ์",
    copyright: "ลิขสิทธิ์",
    thaiTranslate: "แปลไทยโดย",
    size: "ขนาด",
    type: "แนว",
  },
};

export default message;
